<template>
  <b-modal
    id="modal-move-asset"
    ref="refModal"
    title="Di chuyển tài sản"
    :ok-title="isSubmitting ? 'Đang lưu' : 'Lưu'"
    :ok-disabled="isSubmitting"
    cancel-title="Huỷ"
    cancel-variant="outline-secondary"
    size="lg"
    no-close-on-backdrop
    :hide-header-close="true"
    scrollable
    @show="resetModal"
    @hidden="resetModal"
    @ok="onSubmit"
  >
    <!-- Body -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >

      <b-row>
        <b-col
          cols="12"
          class="my-1"
        >
          <div class="d-flex justify-space-between">
            <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
              1. Danh sách tài sản cần di chuyển
            </div>
          </div>
        </b-col>
      </b-row>

      <asset-table :data="item.assets" />

      <b-row>
        <b-col
          cols="12"
          class="my-1"
        >
          <div class="d-flex justify-space-between">
            <div class="d-flex justify-space-between font-small-4 font-weight-bolder text-uppercase text-primary">
              2. Nơi chuyển đến
            </div>
          </div>
        </b-col>
      </b-row>
      <!-- Form -->
      <b-form
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetForm"
      >

        <b-row>

          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="warehouse"
              rules=""
            >
              <select-warehouse
                v-model="itemLocal.warehouse"
                :enable-add="true"
                label="Kho"
                :enable="!itemLocal.apartment"
                :default-value="itemLocal.warehouse"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="apartment"
              rules=""
            >
              <select-apartment
                v-model="itemLocal.apartment"
                :filter-active="true"
                :enable-add="true"
                :enable="!itemLocal.warehouse"
                label="Tòa nhà"
                :default-value="itemLocal.apartment"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
          <b-col md="4">
            <validation-provider
              #default="validationContext"
              name="room"
              rules=""
            >
              <select-room
                v-model="itemLocal.room"
                :filter-active="true"
                :apartment="itemLocal.apartment"
                :enable-add="true"
                label="Phòng"
                :default-value="itemLocal.room"
                :state="getValidationState(validationContext)"
                :error="validationContext.errors[0]"
              />
            </validation-provider>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="12">
            <select-date
              v-model="itemLocal.movingDate"
              label="Ngày di chuyển"
              :default-value="itemLocal.movingDate"
            />
          </b-col>
        </b-row>

        <!-- Note -->
        <b-form-group
          label="Ghi chú"
        >
          <b-form-textarea
            id="note"
            v-model="itemLocal.note"
            placeholder="Ghi chú"
            rows="3"
          />
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BModal,
  BFormGroup,
  BFormTextarea,
  BForm,
  BRow,
  BCol,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, integer, positive } from '@validations';
import formValidation from '@core/comp-functions/forms/form-validation';
import SelectDate from '@/views/components/SelectDate.vue';
import SelectWarehouse from '@/views/components/SelectWarehouse.vue';
import SelectApartment from '@/views/components/SelectApartment.vue';
import SelectRoom from '@/views/components/SelectRoom.vue';
import useMoveAsset from './useMoveAsset';
import AssetTable from './AssetTable.vue';

export default {
  components: {
    BModal,
    BFormGroup,
    BFormTextarea,
    BForm,
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    SelectDate,
    SelectWarehouse,
    SelectApartment,
    SelectRoom,
    AssetTable,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      required,
      integer,
      positive,
    };
  },
  setup(props, { emit }) {
    const {
      refFormObserver, getValidationState, resetForm, clearForm,
    } = formValidation();
    const {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,

      onSubmit,
    } = useMoveAsset(props, emit, refFormObserver);

    return {
      refModal,
      itemLocal,
      isSubmitting,
      resetItemLocal,
      resetModal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    };
  },
};
</script>
