import { ref } from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import { parseDateToString } from '@/auth/utils';

export default function useAssetHandler(props, emit, refFormObserver) {
  const toastification = toast();
  const isSubmitting = ref(false);
  const refModal = ref(null);
  const refForm = ref(refFormObserver);
  const itemLocal = ref(JSON.parse(JSON.stringify(props.item)));
  const resetItemLocal = () => {
    itemLocal.value = JSON.parse(JSON.stringify(props.item));
    isSubmitting.value = false;
  };

  const resetModal = () => {
    resetItemLocal();
  };

  const onSubmit = async bvModalEvt => {
    bvModalEvt.preventDefault();

    const success = await refForm.value.validate();
    if (!success) {
      return;
    }

    try {
      const data = {
        name: itemLocal.value.name,
        brand: itemLocal.value.brand,
        color: itemLocal.value.color,
        model: itemLocal.value.model,
        madeIn: itemLocal.value.madeIn,
        price: itemLocal.value.price,
        quantity: itemLocal.value.quantity,
        status: itemLocal.value.status,
        note: itemLocal.value.note,
        location: itemLocal.value.location,
      };

      if (itemLocal.value.provider && itemLocal.value.provider.id > 0) {
        data.providerId = itemLocal.value.provider.id;
      }
      if (itemLocal.value.type && itemLocal.value.type.id > 0) {
        data.typeId = itemLocal.value.type.id;
      }
      if (itemLocal.value.warehouse && itemLocal.value.warehouse.id > 0) {
        data.warehouseId = itemLocal.value.warehouse.id;
      }
      if (itemLocal.value.apartment && itemLocal.value.apartment.id > 0) {
        data.apartmentId = itemLocal.value.apartment.id;
      }
      if (itemLocal.value.room && itemLocal.value.room.id > 0) {
        data.roomId = itemLocal.value.room.id;
      }

      data.warranty = parseDateToString(itemLocal.value.warranty);

      // attachments
      // if (itemLocal.value.attachments) {
      //   data.attachments = itemLocal.value.attachments.map(object => object.location);
      // }

      isSubmitting.value = true;

      if (itemLocal.value.id && itemLocal.value.id > 0) {
        data.id = itemLocal.value.id;
        store
          .dispatch('asset/updateAsset', data)
          .then(response => { emit('on-item-updated', response.data); })
          .then(() => {
            refModal.value.toggle('#toggle-btn');
          })
          .then(() => {
            emit('refetch-assets');
          })
          .then(() => {
            toastification.showToastUpdateSuccess();
            isSubmitting.value = false;
          })
          .catch(error => {
            toastification.showToastError(error, refForm.value);
            isSubmitting.value = false;
          });
      } else {
        store
          .dispatch('asset/createAsset', data)
          .then(response => { emit('on-item-created', response.data); })
          .then(() => {
            refModal.value.toggle('#toggle-btn');
          })
          .then(() => {
            emit('refetch-assets');
          })
          .then(() => {
            toastification.showToastCreateSuccess();
            isSubmitting.value = false;
          })
          .catch(error => {
            toastification.showToastError(error, refForm.value);
            isSubmitting.value = false;
          });
      }
    } catch (error) {
      toastification.showToastError(error);
    }
  };

  const onDeleteAttachment = item => {

  };

  return {
    refModal,
    refForm,
    itemLocal,
    isSubmitting,
    resetItemLocal,
    resetModal,
    onSubmit,
    onDeleteAttachment,
  };
}
