import {
  ref, watch, onUnmounted, getCurrentInstance,
} from '@vue/composition-api';
import store from '@/store';
import toast from '@/utils/toast';
import moment from 'moment';
import assetStoreModule from '../assetStoreModule';

export default function useAssetList() {
  const STORE_MODULE_NAME = 'asset';

  // Register module
  if (!store.hasModule(STORE_MODULE_NAME)) {
    store.registerModule(STORE_MODULE_NAME, assetStoreModule);
  }

  // UnRegister on leave
  onUnmounted(() => {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME);
    }
  });
  // Use toast
  const toastification = toast();

  // add / edit
  const blankItem = {
    id: 0,
    name: '',
    location: '',
    brand: '',
    color: '',
    model: '',
    madeIn: '',
    price: '',
    quantity: '',
    status: '',
    warranty: moment(new Date()).add(2, 'y'),
    provider: null,
    type: null,
    warehouse: null,
    apartment: null,
    room: null,
    note: '',
    attachments: [],
  };
  const item = ref(JSON.parse(JSON.stringify(blankItem)));
  const resetItem = () => {
    item.value = JSON.parse(JSON.stringify(blankItem));
  };

  const vm = getCurrentInstance().proxy;
  const isHidden = !vm.$can('update', 'asset') && !vm.$can('delete', 'asset');

  // Table Handlers
  const columns = [
    {
      label: 'Mã',
      field: 'code',
    },
    {
      label: 'Thao tác',
      field: 'action',
      sortable: false,
      width: '100px',
      hidden: isHidden,
    },
    {
      label: 'Tên',
      field: 'name',
    },
    {
      label: 'Kho',
      field: 'warehouse.name',
    },
    {
      label: 'Tòa nhà',
      field: 'apartment.name',
    },

    {
      label: 'Tình trạng',
      field: 'status',
    },
    {
      label: 'Giá trị',
      field: 'price',
    },

  ];
  const rows = ref([]);
  const analytics = ref(null);
  // current selected rows
  const selectedRows = ref([]);
  const searchTerm = ref('');
  const provider = ref(null);
  const warehouse = ref(null);
  const assetType = ref(null);
  const apartment = ref(null);
  const room = ref(null);
  // ssr
  const isLoading = ref(false);
  const totalRecords = ref(0);
  const serverParams = ref({
    searchTerm: '',
    filter: {},
    sort: {},
    page: 1,
    perPage: 20,
  });

  const fetchAssets = () => {
    store
      .dispatch('asset/fetchAssets', serverParams.value)
      .then(response => {
        const { total, items } = response.data;
        totalRecords.value = total;
        rows.value = items;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchAnalytics = () => {
    store
      .dispatch('asset/fetchAnalytics', serverParams.value)
      .then(response => {
        analytics.value = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const fetchData = () => {
    fetchAnalytics();
    fetchAssets();
  };

  const downloadImportFile = () => {
    store
      .dispatch('asset/generateImportTemplate')
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };
  const importData = data => {
    const formData = new FormData();
    formData.append('file', data.file);
    store
      .dispatch('asset/importAssets', formData)
      .then(() => {
        fetchData();
        toastification.showToastCreateSuccess();
        vm.$router
          .push({ name: 'jobs' });
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const exportData = () => {
    store
      .dispatch('asset/exportAssets', serverParams.value)
      .then(response => {
        window.location.href = response.data;
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const deleteAssets = assets => {
    store
      .dispatch('asset/deleteAssets', {
        ids: assets.map(_obj => _obj.id),
      })
      .then(() => {
        fetchData();
        toastification.showToastDeleteSuccess();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  const createAsset = () => {
    const data = {
      name: item.value.name,
      brand: item.value.brand,
      color: item.value.color,
      model: item.value.model,
      madeIn: item.value.madeIn,
      price: item.value.price,
      quantity: item.value.quantity,
      status: item.value.status,
      warranty: item.value.warranty,
      note: item.value.note,
    };

    if (item.value.privider && item.value.privider.id > 0) {
      data.providerId = item.value.provider.id;
    }
    if (item.value.type && item.value.type.id > 0) {
      data.typeId = item.value.type.id;
    }
    if (item.value.warehouse && item.value.warehouse.id > 0) {
      data.warehouseId = item.value.warehouse.id;
    }

    store
      .dispatch('asset/createAsset', data)
      .then(() => {
        fetchAssets();
      })
      .catch(error => {
        toastification.showToastError(error);
      });
  };

  //   Methods
  const updateParams = newProps => {
    serverParams.value = { ...serverParams.value, ...newProps };
    fetchData();
  };

  const onPageChange = params => {
    updateParams({ page: params.currentPage });
  };

  const onPerPageChange = params => {
    updateParams({ perPage: params.currentPerPage });
  };

  const onSortChange = params => {
    updateParams({
      sort: {
        type: params[0].type,
        field: params[0].field,
      },
    });
  };

  const onColumnFilter = params => {
    updateParams(params);
  };

  const selectionChanged = params => {
    selectedRows.value = params.selectedRows;
  };

  const onEditItem = data => {
    item.value = data;
  };

  const onMoveItems = data => {
    item.value = {
      assets: data, note: '', movingDate: moment(new Date()).toDate(), warehouse: null, apartment: null, room: null,
    };
  };

  const onDelete = asset => {
    const deleteObject = asset && asset.id > 0 ? [asset] : selectedRows.value;
    deleteAssets(deleteObject);
  };

  //   Watch
  watch(searchTerm, val => {
    updateParams({ searchTerm: val });
  });
  watch(provider, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.providerId = val.id;
    } else {
      delete filter.providerId;
    }
    updateParams({ filter });
  });
  watch(warehouse, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.warehouseId = val.id;
    } else {
      delete filter.warehouseId;
    }
    updateParams({ filter });
  });
  watch(assetType, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.typeId = val.id;
    } else {
      delete filter.typeId;
    }
    updateParams({ filter });
  });
  watch(apartment, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.apartmentId = val.id;
    } else {
      delete filter.apartmentId;
    }
    updateParams({ filter });
  });
  watch(room, val => {
    const { filter } = serverParams.value;
    if (val) {
      filter.roomId = val.id;
    } else {
      delete filter.roomId;
    }
    updateParams({ filter });
  });

  //   API Call

  const refetchAssets = () => {
    fetchAssets();
  };

  return {
    item,
    resetItem,
    analytics,
    columns,
    rows,
    selectedRows,
    searchTerm,
    provider,
    warehouse,
    assetType,
    apartment,
    room,
    isLoading,
    totalRecords,
    serverParams,
    fetchData,
    refetchAssets,
    fetchAssets,
    deleteAssets,
    createAsset,
    onMoveItems,

    updateParams,
    onPageChange,
    onPerPageChange,
    onSortChange,
    onColumnFilter,
    selectionChanged,
    onEditItem,
    onDelete,
    downloadImportFile,
    importData,
    exportData,
  };
}
